<template>
<section class="customer-manager">
    <el-form :inline="true" class="search-wrapper" :mode="searchMode">
        <el-form-item label="时间">
            <el-date-picker
                v-model="searchMode.time"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
            </el-date-picker>
        </el-form-item>
        <el-form-item label="搜索">
            <el-input v-model="searchMode.search" class="search-input" placeholder="客户昵称/客户手机号"></el-input>
        </el-form-item>
        <el-form-item>
            <el-button class="search-btn" type="primary" @click="getList">搜索</el-button>
        </el-form-item>
    </el-form>
    <h2 class="customer-title">客户列表</h2>
    
    <!-- table -->
    <el-table
        class="table-wrapper"
        :data="list"
        :header-cell-style="{color: '#000',fontWeigth: 'bold',background: '#FAFAFA',textAlign:'center'}"
        style="width:100%; min-height: 500px;">
        <el-table-column
            label="用户昵称"
            width="150">
            <template slot-scope="scope">
                <div class="flex">
                    <el-image :src="scope.row.avatarUrl" alt="" class="table-img"></el-image>
                    <p class="table-name">
                        <span>{{scope.row.nickName}}</span>
                    </p>
                </div>
            </template>
        </el-table-column>
        <el-table-column
            prop="phone.phoneNumber"
            align="center"
            label="手机号">
        </el-table-column>
        <el-table-column
            align="center"
            label="所在地区">
            <template slot-scope="scope">
                {{scope.row.province}}{{scope.row.city}}
            </template>
        </el-table-column>
        <el-table-column
            align="center"
            label="性别">
            <template slot-scope="scope">
               <span v-if="scope.row.gender==1">男</span>
               <span v-if="scope.row.gender==2">女</span>
            </template>
        </el-table-column>
        <el-table-column
            align="center"
            label="身份">
            <template slot-scope="scope">
                <span v-if="scope.row.isBoss">Boss</span>
                <span v-else-if="scope.row.phone&&scope.row.phone.myC&&scope.row.phone.myC.id">代理</span>
                <span v-else>客户</span>
            </template>
        </el-table-column> 
        <el-table-column
            prop="createTimeS"
            align="center"
            label="最后活跃时间">
            <template slot-scope="scope">
                {{scope.row.lastTimeS*1000 | formatDate}}
            </template>
        </el-table-column>
        <el-table-column
            align="center"
            label="操作">
            <template slot-scope="scope">
                <span class="table-btn" @click="changeBossHandler(scope.row._id)">设置</span>
            </template>
        </el-table-column>
    </el-table>

    <el-pagination
        class="pagination"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10,15]"
        :page-size="rows"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
    </el-pagination>

    <!-- setting -->
    <el-dialog title="身份设置" :visible.sync="dialogSettingVisible">
        <el-form :model="settingForm" :rules="settingRules">
            <el-form-item label="身份" prop="identity">
            <el-select v-model="settingForm.identity" placeholder="请选择身份">
                <el-option label="boss" :value="true"></el-option>
            </el-select>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="changeCancelHandler">取 消</el-button>
            <el-button type="primary" @click="changeSureHandler">确 定</el-button>
        </div>
    </el-dialog>
</section>
</template>

<script>
export default {
    data(){
        return {
            searchMode:{
                time:[],
                search:''
            },
            list:[],
            currentPage:1,
            rows:10,
            total:0,
            dialogSettingVisible:false,
            settingForm:{identity:''},
            settingRules:{
                identity:[{required: true, message: '请选择身份', trigger: 'change'}]
            },
            currentId:'',
        }
    },
    created(){
        this.getList();
    },
    methods:{
        async getList(){
            const rs = await this.$https.cloud({
                action:'user',
                type:'list',
                formData:{
                    page: this.currentPage,
                    rows: this.rows,
                    startTime: this.searchMode.time[0],
                    endTime: this.searchMode.time[1],
                    search : this.searchMode.search
                }
            });
            if(rs.code!=0){
                this.$message.error(rs.msg);
                return;
            }
            this.total = rs.data.total;
            this.list = rs.data.list;
        },

        //  分页 ========
        handleSizeChange(e){
            this.rows = e;
            this.getList();
        },
        handleCurrentChange(e){
            this.currentPage = e;
            this.getList();
        },
        changeBossHandler(id){
            this.currentId = id;
            this.dialogSettingVisible = true;
        },
        changeCancelHandler(){
            this.dialogSettingVisible = false;
        },
        async changeSureHandler(){
            this.dialogSettingVisible = false;
            const rs = await this.$https.cloud({
                action:'user',
                type:'setting',
                formData:{
                    _id: this.currentId,
                    isBoss: this.settingForm.identity || false
                }
            });
            if(rs.code==0) this.getList();
            this.dialogSettingVisible = false;
            this.$message(rs.msg);
        }

    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/common.scss';

.customer-manager {
    padding:  0 10px;
    background: #fff;

    .search-wrapper {
        padding-top: 20px;
        border-bottom: 2px solid #ccc;
        font-size: 14px;
        .search-input {
            width: 225px;
            display: inline-block;
            margin-right: 25px;
        }
    }

    .customer-title {
        font-size: 16px;
        font-weight: normal;
        line-height: 1;
        padding:  38px 0 13px 0;
    }

    .table-wrapper {
        border-top: 1px solid #ccc;

        .table-img {
            width: 45px;
            height: 45px;
            margin-right: 0.8rem;
        }

        .table-name {
            font-size: 14px;
            line-height: 22px;
            flex: 1;
        }

        .table-btn {
            color: $baseColor;
            padding: 0 6px;
        }

        .table-btn +.table-btn {
            border-left: 1px solid #ccc;
        }

        .status-up {
            color: #52C31C;
        }

        .status-down {
            color: #FF7843;
        }
    }

    .pagination {
        padding:  10px;
        text-align: right;
    }
}
</style>